import firebase from 'firebase';

const USE_LOCAL_API = false;

const config = {
  apiKey: "AIzaSyBb8MxslSLv4AnOhWFH2vSJuxMoEi5EeSk",
  authDomain: "bluve-4c-teste.firebaseapp.com",
  databaseURL: "https://bluve-4c-teste.firebaseio.com",
  projectId: "bluve-4c-teste",
  storageBucket: "bluve-4c-teste.appspot.com",
  messagingSenderId: "219758613774",
  appId: "1:219758613774:web:369b210dbaf37132c6735f",
  measurementId: "G-ZCPXRYKP6S",
};

function getUrl() {
  if (USE_LOCAL_API) {
    return 'http://localhost:5001/bluve-4c-teste/us-central1/api/doc';
  }
  return 'https://us-central1-bluve-4c-teste.cloudfunctions.net/api/doc';
}

export const fireBase = firebase.initializeApp(config);
export const fbDatabase = firebase.firestore();

export const API_BLUVE_DOC = `${getUrl()}/api-bluve`;
export const API_FILA_DOC = `${getUrl()}/api-fila`;
