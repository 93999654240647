import { fireBase, fbDatabase } from "../../firebase/firebase";

export async function getUsuario(uid) {
  const usuario = await fbDatabase.collection('usuarios')
    .where('uid', '==', uid)
    .where('desenvolvedor', '==', true)
    .get();
  return usuario;
}

export async function autenticar(email, senha) {
  let auth = {};
  try {
    const obj = await fireBase
      .auth()
      .signInWithEmailAndPassword(email, senha);

    if (obj) {
      const usuario = await getUsuario(obj.user.uid);

      if (usuario.empty) {
        auth = {
          isOk: false,
          title: 'Usuário Sem Permissão',
          message: 'O usuário não possui permissão para acessar essa página'
        }
      } else {
        auth = {
          isOk: true,
          uid: obj.user.uid
        };
      }
    };

    return auth;
  } catch (error) {
    auth = {
      isOk: false,
      title: 'Erro no Login'
    }

    if (error.code === 'auth/user-not-found') {
      auth.message = 'Usuário não encontrado';
    }
    if (error.code === 'auth/wrong-password') {
      auth.message = 'Senha inválida';
    }
    if (error.code === 'auth/too-many-requests') {
      auth.message = 'Você excedeu o número de tentativas de login. por favor, tente novamente mais tarde ou troque a sua senha.';
    }

    return auth;
  }
}
