import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import CryptoJS from 'crypto-js';
import { getItem, getToken } from '../../components/token/tokenFunctions';
import history from '../../components/history/history';
import logo from '../../assets/template/images/logo-bluve-azul.png'
import { getUsuario } from '../login/loginF';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.accessDoc = this.accessDoc.bind(this);
    this.apiBluve = this.apiBluve.bind(this);
    this.apiFila = this.apiFila.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      loadingBluve: false,
      loadingFila: false,
      loadingSair: false
    };
  };

  async accessDoc(next) {
    const cookie = getItem('uid');
    const uid = CryptoJS.AES
      .decrypt(cookie, 'sira')
      .toString(CryptoJS.enc.Utf8);

    const usuario = await getUsuario(uid);
    if (!usuario.empty) {
      getToken();
      next();
    }
  };

  async apiBluve() {
    this.setState({ loadingBluve: true });
    setTimeout(() => {
      this.setState({ loadingBluve: false });
      history.push('/home/api-bluve')
      history.go();
    }, 500);
  };

  async apiFila() {
    this.setState({ loadingFila: true });
    setTimeout(() => {
      this.setState({ loadingFila: false });
      history.push('/home/api-fila')
      history.go();
    }, 500);
  };

  async logout() {
    this.setState({ loadingSair: true });
    document.cookie = `uid=; Max-Age=-99999999;`
    document.cookie = `token=; Max-Age=-99999999;`

    setTimeout(() => {
      this.setState({ loadingFila: false });
      history.push('/login')
      history.go();
    }, 500);
  };

  render() {
    return (
      <div className='page'>
        <div className='container'>
          <div className='title'>
            Doc's
          </div>

          <img className='logo' alt='Logo do Bluve' src={logo} />

          <div>
            <Button
              loading={this.state.loadingBluve}
              type="primary"
              htmlType="submit"
              className="button"
              onClick={() => { this.accessDoc(this.apiBluve) }}
            >
              API Bluve
            </Button>

            <Button
              loading={this.state.loadingFila}
              type="primary"
              htmlType="submit"
              className="button"
              onClick={() => { this.accessDoc(this.apiFila) }}
            >
              API Fila
            </Button>

            <Button
              loading={this.state.loadingSair}
              type="primary"
              htmlType="submit"
              className="button"
              danger
              onClick={this.logout}
            >
              Sair
            </Button>
          </div>
        </div>
      </div>
    )
  };
};

export default withRouter(Home);
