import React from 'react';
import { Button, Form, Input } from 'antd';
import CryptoJS from 'crypto-js';
import mensagem from '../../components/message/Message';
import history from '../../components/history/history';
import { autenticar } from './loginF';
import './login.css';
import logo from '../../assets/template/images/logo-bluve-azul.png'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      loading: false
    };
  };

  async handleSubmit(values) {
    this.setState({ loading: true });
    const auth = await autenticar(values.email, values.senha);

    if (auth.isOk) {
      this.setState({ loading: false });

      const uid = CryptoJS.AES.encrypt(auth.uid, 'sira');
      document.cookie = `uid=${uid}`

      setTimeout(() => {
        history.push('/home')
        history.go();
      }, 500);
    } else {
      this.setState({ loading: false });
      mensagem.openNotificationWithIcon(
        'error',
        auth.title,
        auth.message
      );
    }
  }

  render() {
    return (
      <div className='page'>
        <div className='container'>
          <div className='title'>
            Doc's
          </div>

          <img className='logo' alt='Logo do Bluve' src={logo} />

          <Form onFinish={this.handleSubmit}>
            <Form.Item
              name='email'
              rules={[{ required: true, message: 'Informe seu email de login' }]}
            >
              <Input placeholder="Email" style={{ width: '80%' }} />
            </Form.Item>
            <Form.Item
              name='senha'
              rules={[{ required: true, message: 'Informe a sua senha de login' }]}
            >
              <Input.Password type="password" placeholder="Senha" style={{ width: '80%', marginTop: '1rem' }} />
            </Form.Item>
            <Form.Item>
              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                className="button"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div >
    )
  };
};

export default (Login);
