import { fireBase } from "../../firebase/firebase";

export async function getToken() {
  const token = await fireBase
    .auth()
    .currentUser
    .getIdToken(true)
    .then(function (idToken) {
      return "bearer " + idToken;
    })
    .catch(function (error) {
      console.log(error.message);
      return;
    });

  document.cookie = `token=${token}`;
}

export function getItem(key) {
  const cookies = document.cookie
    .split(';')
    .map(cookie => cookie.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
  return cookies[key];
}
