import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Login from './pages/login/login';
import Home from './pages/home/home';
import { API_BLUVE_DOC, API_FILA_DOC } from './firebase/firebase';
import { getItem } from './components/token/tokenFunctions';


class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {/* Login */}

        <Route exact path='/login' component={Login}>
          {getItem('uid') && (<Redirect to='home' />)}
        </Route>

        <Redirect exact path='/' to='/login' />

        {/* Home */}
        <PrivateRoute exact path='/home'>
          <Home />
        </PrivateRoute>

        {/* Páginas das Documentações */}
        <PrivateRoute exact path='/home/api-bluve'>
          <meta
            httpEquiv='refresh'
            content={`0; URL=${API_BLUVE_DOC}/${getItem('token')}/index.html`} />
        </PrivateRoute>

        <PrivateRoute exact path='/home/api-fila'>
          <meta
            httpEquiv='refresh'
            content={`0; URL=${API_FILA_DOC}/${getItem('token')}/index.html`} />
        </PrivateRoute>
      </Switch>
    )
  }
};

export default withRouter(Routes);

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (getItem('uid')) {
          return children;
        }

        return <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      }}
    />
  );
}
